<script setup>
import {Head} from '@inertiajs/vue3'
import SubscribersLayout from "../../../Layouts/SubscribersLayout.vue";
import MagicLinkForm from "../../../../_Components/MagicLinkForm.vue";
import { themes } from '../../../../_Themes/themes';

const props = defineProps({
    companyId: String,
});

const themeSelected = themes('beautydose');

</script>

<template>
    <Head title="Inicia sesión"/>

    <SubscribersLayout :themeSelected="themeSelected">
        <h1 class="text-beautydose-pink uppercase text-4xl font-medium text-center">Administra tu suscripción</h1>
        <p class="text-xl text-beautydose-blue-dark mt-5 text-center">
            Bienvenido al portal de suscriptores de Beauty Dose.
        </p>
        <p class="text-xl text-beautydose-blue-dark mt-5 text-center">
            A través de este portal podrás obtener el resumen de tu suscripción, así como actualizar tu método de pago y
            consultar el historial de tus pagos realizados a Beauty Dose.
        </p>
        <p class="text-xl text-beautydose-blue-dark mt-5 text-center">
            Obtén tu link de acceso al portal por medio de tu correo electrónico asociado a tu suscripción.
        </p>

        <div class="mt-16 max-w-[400px] mx-auto">
            <MagicLinkForm
                :company="companyId"
                classes-input="ring-[#CD7768] focus:ring-[#F1D2CC] rounded-md"
                classes-button="bg-beautydose-pink text-white rounded-md"
            >
                Obtener link de acceso
            </MagicLinkForm>
        </div>
    </SubscribersLayout>
</template>

